<template>
  <v-container class="grey lighten-4" style="height: 100%">
    <v-col display="flex">
      <div v-for="(item, index) in features" :key="item._id">
        <v-row class="mb-6">
          <v-card color="#FFA500">
            <v-img :src="item.feature_img" style="height: 170px"></v-img>
            <v-card-actions
              @click="show[index].show = !show[index].show"
              style="color: white; font-size: 17px; font-weight: bold;margin: 1px;"
            >
              {{ item.feature_name }}を見る
              <!-- <v-card-text color="orange lighten-2">Explore</v-card-text> -->
              <v-spacer></v-spacer>
              <v-icon>{{ show[index].show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-card-actions>
            <v-expand-transition>
              <div v-show="show[index].show">
                <v-list style="background-color: white">
                  <v-list-item v-for="tag in item.tags" @click="linkTo(tag._id)" :key="tag._id">
                    <v-icon color="grey" style="margin-right: 5px">
                      {{ findIcon(tag.tag_name) }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-black"
                        v-text="tag.tag_name"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="#f57c00">mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list>
              </div>
            </v-expand-transition>
          </v-card>
        </v-row>
      </div>
    </v-col>
    <BottomNavigation app></BottomNavigation>
  </v-container>
</template>

<script>
  import BottomNavigation from '@/components/BottomNavigation.vue';
  import Methods from '@/api/method.js';
  export default {
    name: 'RecoseleUserFeatures',
    components: {
      BottomNavigation,
    },
    data() {
      return {
        features: [],
        show: [],
      };
    },
    mounted() {
      this.getFeatures();
    },
    methods: {
      async getFeatures() {
        const res = await Methods.getFeatures();
        this.features = res.data.features;
        this.features.map((data) => {
          this.show.push({ id: data._id, show: false });
        });
      },
      findIcon(name) {
        const iconList = [
          { name: 'ベーカリー', icon: 'mdi-baguette' },
          { name: 'パンのお供', icon: 'mdi-cheese' },
          { name: 'その他', icon: 'mdi-egg-fried' },
          { name: 'ティータイム', icon: 'mdi-tea' },
          { name: 'ホームパーティー', icon: 'mdi-party-popper' },
        ];
        let icon = 'mdi-circle-small';
        const findIcon = iconList.find((data) => data.name == name);
        if (findIcon) {
          icon = findIcon.icon;
        }
        return icon;
      },
      linkTo(tag) {
        this.$router.push('/features/' + tag);
      },
    },
  };
</script>

<style lang="scss" scoped></style>
